<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="7" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
              >Kuisioner (7/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
              >Kondisi Pribadi &amp; Riwayat Medis
            </c-heading>
          </c-box>
          <form @submit.prevent="submit">
            <c-box
              marginTop="80px"
              v-chakra="{
                'input[type=radio]:checked+.css-1nskzgf': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'input[type=checkbox]:checked+.css-fd41dq': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'div.css-huvqms': {
                  fontSize: '18px',
                  color: '#555555',
                },
              }"
            >
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.job.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Pekerjaan
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Pekerjaan"
                  v-model="jobSelection"
                  required
                >
                  <option value="Pegawai swasta">Pegawai swasta</option>
                  <option value="PNS">PNS</option>
                  <option value="Wirausaha/Self-employed/Freelancer">
                    Wirausaha/Self-employed/Freelancer
                  </option>
                  <option value="Ibu rumah tangga">Ibu rumah tangga</option>
                  <option value="Mahasiswa">Mahasiswa</option>
                  <option value="Masih sekolah">Masih sekolah</option>
                  <option value="Lainnya">Lainnya</option>
                </c-select>
                <c-input
                  height="62px"
                  mt="20px"
                  v-if="jobSelection === 'Lainnya'"
                  type="text"
                  placeholder="Lainnya"
                  v-model="jobOther"
                />
                <c-form-helper-text
                  v-if="!$v.job.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.lastEducation.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Pendidikan Terakhir
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Pendidikan Terakhir"
                  v-model="educationSelection"
                  required
                >
                  <option value="S2 atau S3">S2 atau S3</option>
                  <option value="S1 atau Diploma">S1 atau Diploma</option>
                  <option value="SMA">SMA</option>
                  <option value="SMP">SMP</option>
                  <option value="SD">SD</option>
                  <option value="Lainnya">Lainnya</option>
                </c-select>
                <c-input
                  height="62px"
                  mt="20px"
                  v-if="educationSelection === 'Lainnya'"
                  type="text"
                  placeholder="Lainnya"
                  v-model="educationOther"
                />
                <c-form-helper-text
                  v-if="!$v.lastEducation.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.mentalDisorder.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apakah kamu memiliki masalah atau gangguan berikut?
                </c-form-label>
                <c-checkbox-group
                  size="lg"
                  variant-color="green"
                  spacing="2"
                  v-model="mentalDisorder"
                  required
                >
                  <c-checkbox value="Tidak ada"> Tidak ada</c-checkbox>
                  <c-checkbox
                    value="Depresi"
                    :is-disabled="mentalDisorder.includes('Tidak ada')"
                  >
                    Depresi
                  </c-checkbox>
                  <c-checkbox
                    value="Anxiety"
                    :is-disabled="mentalDisorder.includes('Tidak ada')"
                  >
                    Anxiety (Kecemasan berlebih)
                  </c-checkbox>
                  <c-checkbox
                    value="Eating Disorder"
                    :is-disabled="mentalDisorder.includes('Tidak ada')"
                  >
                    Eating Disorder
                  </c-checkbox>
                  <c-checkbox
                    value="Seasonal Affective Disorder"
                    :is-disabled="mentalDisorder.includes('Tidak ada')"
                  >
                    Seasonal Affective Disorder (Gangguan mood musiman)
                  </c-checkbox>
                  <c-checkbox
                    value="Maaslah kesehatan mental lainnya"
                    :is-disabled="mentalDisorder.includes('Tidak ada')"
                  >
                    Masalah kesehatan mental lainnya
                  </c-checkbox>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.mentalDisorder.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.disease.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Adakah saat ini kamu dinyatakan mengidap salah satu kondisi di
                  bawah ini oleh dokter?
                </c-form-label>
                <c-checkbox-group
                  size="lg"
                  variant-color="green"
                  spacing="2"
                  v-model="disease"
                  required
                >
                  <c-checkbox value="Tidak ada">Tidak ada</c-checkbox>
                  <c-checkbox
                    value="Maag"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Maag
                  </c-checkbox>
                  <c-checkbox
                    value="Tinggi kolesterol"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Tinggi kolesterol
                  </c-checkbox>
                  <c-checkbox
                    value="Tinggi asam urat"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Tinggi asam urat
                  </c-checkbox>
                  <c-checkbox
                    value="Anemia"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Anemia
                  </c-checkbox>
                  <c-checkbox
                    value="Diabetes Mellitus Tipe II (Kencing Manis)"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Diabetes Mellitus Tipe II (Kencing Manis)
                  </c-checkbox>
                  <c-checkbox
                    value="Diabetes Melitus Tipe I (Bawaan/Keturunan)"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Diabetes Melitus Tipe I (Bawaan/Keturunan)
                  </c-checkbox>
                  <c-checkbox
                    value="Penyakit Jantung"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Penyakit Jantung
                  </c-checkbox>
                  <c-checkbox
                    value="Penyakit Ginjal Kronis (Belum Cuci Darah)"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Penyakit Ginjal Kronis (Belum Cuci Darah)
                  </c-checkbox>
                  <c-checkbox
                    value="Penyakit Ginjal Kronis (Sudah Cuci Darah)"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Penyakit Ginjal Kronis (Sudah Cuci Darah)
                  </c-checkbox>
                  <c-checkbox
                    value="Kanker"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Kanker
                  </c-checkbox>
                  <c-checkbox
                    value="Autoimun"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Autoimun
                  </c-checkbox>
                  <c-checkbox
                    value="Lupus"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    Lupus
                  </c-checkbox>
                  <c-checkbox
                    value="HIV/AIDS"
                    :is-disabled="disease.includes('Tidak ada')"
                  >
                    HIV/AIDS
                  </c-checkbox>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.disease.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.symptom.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Adakah kamu mengalami keluhan dibawah ini dalam 3 hari
                  terakhir?
                </c-form-label>
                <c-checkbox-group
                  size="lg"
                  variant-color="green"
                  spacing="2"
                  marginTop="20px"
                  v-model="symptom"
                  required
                >
                  <c-checkbox value="Tidak ada">Tidak ada</c-checkbox>
                  <c-checkbox
                    value="Mual"
                    :is-disabled="symptom.includes('Tidak ada')"
                  >
                    Mual
                  </c-checkbox>
                  <c-checkbox
                    value="Muntah"
                    :is-disabled="symptom.includes('Tidak ada')"
                  >
                    Muntah
                  </c-checkbox>
                  <c-checkbox
                    value="Susah buang air besar dalam 3 hari terakhir"
                    :is-disabled="symptom.includes('Tidak ada')"
                  >
                    Susah buang air besar dalam 3 hari terakhir
                  </c-checkbox>
                  <c-checkbox
                    value="Kesulitan mengunyah"
                    :is-disabled="symptom.includes('Tidak ada')"
                  >
                    Kesulitan mengunyah
                  </c-checkbox>
                  <c-checkbox
                    value="Kesulitan menelan"
                    :is-disabled="symptom.includes('Tidak ada')"
                  >
                    Kesulitan menelan
                  </c-checkbox>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.symptom.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="80px"
                :is-invalid="$v.drugsConsumption.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Adakah konsumsi obat-obatan rutin yang perlu kami ketahui?
                  Jika ada, mohon sebutkan obat untuk apa atau merk obatnya
                </c-form-label>
                <!-- <c-input-group size="md"> -->
                <textarea
                  rows="3"
                  v-chakra
                  placeholder="Tuliskan di sini"
                  d="block"
                  border="1px solid #E2E8F0"
                  border-radius="md"
                  minHeight="150px"
                  type="text"
                  lineHeight="20px"
                  p="10px"
                  w="100%"
                  v-model="drugsConsumption"
                />
                <!-- </c-input-group> -->
              </c-form-control>

              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.currentBehavior.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Adakah kamu termasuk salah satu dibawah ini?
                </c-form-label>
                <c-checkbox-group
                  variant-color="green"
                  size="lg"
                  spacing="2"
                  marginTop="20px"
                  v-model="currentBehavior"
                  required
                >
                  <c-checkbox
                    value="Saat ini merokok"
                    :is-disabled="
                      currentBehavior.includes(
                        'Tidak termasuk salah satu diatas'
                      )
                    "
                  >
                    Saat ini merokok
                  </c-checkbox>
                  <c-checkbox
                    value="Pernah merokok tetapi sudah berhenti"
                    :is-disabled="
                      currentBehavior.includes(
                        'Tidak termasuk salah satu diatas'
                      )
                    "
                  >
                    Pernah merokok tetapi sudah berhenti
                  </c-checkbox>
                  <c-checkbox
                    value="Terkadang mengonsumsi minuman beralkohol"
                    :is-disabled="
                      currentBehavior.includes(
                        'Tidak termasuk salah satu diatas'
                      )
                    "
                  >
                    Terkadang mengonsumsi minuman beralkohol
                  </c-checkbox>
                  <c-checkbox value="Tidak termasuk salah satu diatas">
                    Tidak termasuk salah satu diatas
                  </c-checkbox>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.currentBehavior.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                :is-invalid="$v.supplementConsumption.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Adakah jenis multivitamin atau tablet suplementasi yang kamu
                  konsumsi? Jika ada, mohon seburkan jenisnya atau merknya
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    type="text"
                    is-invalid
                    error-border-color="red.300"
                    placeholder="Tuliskan di sini"
                    height="62px"
                    v-model="supplementConsumption"
                  />
                </c-input-group>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.otherDisease.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Adakah riwayat penyakit atau kondisi kesehatan lainnya yang
                  perlu kami ketahui?
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    type="text"
                    placeholder="Tuliskan di sini"
                    height="62px"
                    v-model="otherDisease"
                    required
                  />
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.otherDisease.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.communicationIntensity.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Seperti apa intensitas komunikasi yang kamu inginkan dalam
                  program?
                </c-form-label>

                <c-stack>
                  <c-radio-group required v-model="communicationIntensity">
                    <c-radio
                      size="lg"
                      value="Perhatian dan sering mengingatkan"
                    >
                      Perhatian dan sering mengingatkan
                    </c-radio>
                    <c-radio
                      size="lg"
                      value="Mengingatkan seperlunya saja namun selalu ada mendampingi ketika dibutuhkan"
                    >
                      Mengingatkan seperlunya saja namun selalu ada mendampingi
                      ketika dibutuhkan
                    </c-radio>
                  </c-radio-group>
                </c-stack>
                <c-form-helper-text
                  v-if="!$v.communicationIntensity.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="80px"
                :is-invalid="$v.expectNutritionist.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apa lagi yang kamu harapkan dari Ahli Gizi Dietela?
                </c-form-label>
                <!-- <c-input-group size="md"> -->
                <textarea
                  rows="3"
                  v-chakra
                  placeholder="Tuliskan di sini"
                  d="block"
                  border="1px solid #E2E8F0"
                  border-radius="md"
                  minHeight="150px"
                  type="text"
                  lineHeight="20px"
                  p="10px"
                  w="100%"
                  v-model="expectNutritionist"
                />
                <!-- </c-input-group> -->
                <c-form-helper-text>
                  Silahkan tuliskan sikap, cara komunikasi, atau jenis-jenis
                  informasi yang kamu harapkan dari Ahli Gizi kamu
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="80px"
                :is-invalid="$v.expectProgram.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Apa yang kamu harapkan dari program yang kamu pilih?
                </c-form-label>
                <!-- <c-input-group size="md"> -->
                <textarea
                  rows="3"
                  v-chakra
                  id="snack"
                  placeholder="Tuliskan di sini"
                  d="block"
                  border="1px solid #E2E8F0"
                  border-radius="md"
                  minHeight="150px"
                  type="text"
                  lineHeight="20px"
                  p="10px"
                  w="100%"
                  v-model="expectProgram"
                />
                <!-- </c-input-group> -->
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner6', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                  :is-disabled="isLoading"
                  id="submit"
                >
                  {{ isLoading ? "Submitting..." : "Selanjutnya" }}
                </c-button>
              </c-flex>
            </c-box>
          </form>
        </c-box>
      </c-box>
    </c-box>
    <c-box v-if="containErrors" pos="fixed" bottom="20px" right="20px">
      <c-alert status="warning">
        <c-alert-icon />
        <c-alert-description>
          <c-text fontSize="14px" color="red.500">
            {{ errors }}
          </c-text>
        </c-alert-description>
      </c-alert>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import required from "vuelidate/lib/validators/required"
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import { mapGetters, mapState } from "vuex"
import programIdMixins from "@/views/client/kuisioner/programId-mixins"
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"

export default {
  name: "kuisioner7",
  components: {
    Header,
    Footer,
    Breadcrumb,
    Steps,
    DinamisForm,
  },
  mixins: [programIdMixins],
  data() {
    return {
      jobSelection: null,
      jobOther: null,
      educationSelection: null,
      educationOther: null,
      mentalDisorder: [],
      disease: [],
      symptom: [],
      drugsConsumption: null,
      // specialMedicine: null,
      currentBehavior: [],
      supplementConsumption: null,
      otherDisease: null,
      communicationIntensity: null,
      expectNutritionist: null,
      expectProgram: null,

      // ---
      isLoading: false,
      containErrors: false,
      errors: "",
      //-- dinamis form
      questions: [],
    }
  },
  validations: {
    job: { required },
    lastEducation: { required },
    mentalDisorder: { required },
    disease: { required },
    symptom: { required },
    // specialMedicine: {required},
    currentBehavior: { required },
    otherDisease: { required },
    communicationIntensity: { required },
    expectNutritionist: {},
    expectProgram: {},
    drugsConsumption: {},
    supplementConsumption: {},
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.medicalHistory,
    }),
    ...mapGetters({
      axios: "axios",
    }),
    job() {
      return this.jobSelection === "Lainnya" ? this.jobOther : this.jobSelection
    },
    lastEducation() {
      return this.educationSelection === "Lainnya"
        ? this.educationOther
        : this.educationSelection
    },
    value() {
      return {
        job: this.job,
        lastEducation: this.lastEducation,
        mentalDisorder: this.mentalDisorder,
        disease: this.disease,
        symptom: this.symptom,
        drugsConsumption: this.drugsConsumption,
        // specialMedicine: this.specialMedicine,
        currentBehavior: this.currentBehavior,
        supplementConsumption: this.supplementConsumption,
        otherDisease: this.otherDisease,
        communicationIntensity: this.communicationIntensity,
        expectNutritionist: this.expectNutritionist,
        expectProgram: this.expectProgram,
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.jobSelection = val.job
        this.jobOther = val.jobOther
        this.educationSelection = val.lastEducation
        this.educationOther = val.lastEducationOther
        this.mentalDisorder = val.mentalDisorder
        this.disease = val.disease
        this.symptom = val.symptom
        this.drugsConsumption = val.drugsConsumption
        this.specialMedicine = val.specialMedicine
        this.currentBehavior = val.currentBehavior
        this.supplementConsumption = val.supplementConsumption
        this.otherDisease = val.otherDisease
        this.communicationIntensity = val.communicationIntensity
        this.expectNutritionist = val.expectNutritionist
        this.expectProgram = val.expectProgram
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return

        await this.$store.dispatch(
          "kuisioner/getV2ClientForCategory",
          "medical_history"
        )
        await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions =
          this.$store.getters["kuisioner/questionFor"]("medical_history")
      },
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isLoading = true
      await this.$store.dispatch("kuisioner/setMedicalHistory", {
        programId: this.$route.params.programId,
        value: this.value,
        answers: this.answers,
      })
      await this.$store
        .dispatch("kuisioner/postV2", this.programId)
        .then(() => {
          this.$store.dispatch("kuisioner/clear")
          this.$router.push({
            name: "client.success",
            query: { kuesioner_id: "xxxx" },
          })
        })
        .catch((e) => {
          if (e.response.data?.message === "quizioner found") {
            this.$store.dispatch("kuisioner/clear")
            this.$router.push({
              name: "client.success",
              query: { kuesioner_id: "xxxx" },
            })
          } else {
            this.$errorToastFromCatch(e)
          }
        })
      this.isLoading = false
    },
  },
}
</script>

<style></style>
