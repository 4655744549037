import { render, staticRenderFns } from "./kuisioner-7.vue?vue&type=template&id=d4e7d1b4&"
import script from "./kuisioner-7.vue?vue&type=script&lang=js&"
export * from "./kuisioner-7.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CSelect: require('@chakra-ui/vue').CSelect, CInput: require('@chakra-ui/vue').CInput, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CCheckbox: require('@chakra-ui/vue').CCheckbox, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CInputGroup: require('@chakra-ui/vue').CInputGroup, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CStack: require('@chakra-ui/vue').CStack, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CAlertDescription: require('@chakra-ui/vue').CAlertDescription, CAlert: require('@chakra-ui/vue').CAlert})
